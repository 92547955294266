import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { COLLECTIONS } from 'src/utils/constants';

// Initial state
const initialState = {
  isLoading: false,
  error: null,
  success: null,
  data: [],
  limit: 10,
  page: 1,
  totalPages: 1,
  totalObjects: 0,
  collection: COLLECTIONS.smartwatches,
};

// Create slice
const slice = createSlice({
  name: 'workouts',
  initialState,
  reducers: {
    // Start loading
    startLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = null;
      state.data = {
        ...state.data,
      }; // Keep the current data while loading
    },

    // Has error
    hasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get workouts success
    getWorkoutsSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = null;
      state.data = action.payload;
    },

    // Set page
    setPage(state, action) {
      state.page = action.payload;
    },

    // Reset data
    resetData(state) {
      state.data = [];
      state.page = 1;
    },

    // Set total pages
    setTotalPages(state, action) {
      state.totalPages = action.payload;
    },

    // Set total objects
    setTotalObjects(state, action) {
      state.totalObjects = action.payload;
    },

    // Set collection
    setCollection(state, action) {
      state.collection = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export function getWorkouts() {
  return async (dispatch, getState) => {
    const { isLoading, limit, page, collection } = getState().workouts;

    if (isLoading) return;

    dispatch(actions.startLoading());
    try {
      const response = await axios.get(
        `/v1/data/workouts?limit=${limit}&page=${page}&collection=${collection}`
      );
      const {
        data: { data, total, totalPages },
      } = response;
      const mergedWorkouts = [];
      data.forEach((workout, index) => {
        if (
          workout?.workout?.partners?.length > 0 &&
          index > 0 &&
          // 10 minutes difference between workouts (matched name and approx start time)
          Math.abs(
            workout.workout.start_timestamp -
              data[index - 1].workout.start_timestamp
          ) < 600000
        ) {
          const name = workout.name;
          const lastWorkout = data[index - 1];
          const lastWorkoutPartnerUserName =
            lastWorkout.workout?.partners?.[0]?.name;
          // only add the workout if the partner name is different from the last workout user name
          if (name !== lastWorkoutPartnerUserName) {
            mergedWorkouts.push(workout);
          }
        } else {
          mergedWorkouts.push(workout);
        }
      });
      dispatch(actions.getWorkoutsSuccess(mergedWorkouts));
      dispatch(actions.setTotalPages(totalPages));
      dispatch(actions.setTotalObjects(total));
    } catch (error) {
      console.error('Error fetching workouts:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
        dispatch(actions.hasError(error.response.data));
      } else if (error.request) {
        console.error('No response received:', error.request);
        dispatch(actions.hasError('No response received from server.'));
      } else {
        console.error('Error setting up request:', error.message);
        dispatch(actions.hasError(error.message));
      }
    }
  };
}

export function resetWorkouts() {
  return (dispatch) => {
    dispatch(actions.resetData());
  };
}
