import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

// Slice to fetch User Detail

const initialState = {
  isLoading: false,
  error: null,
  success: null,
  data: null,
  partner: null,
  isPartnerLoading: false,
};

const slice = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.success = null;
      state.isLoading = true;
      state.error = false;
      state.data = null;
    },

    startPartnerLoading(state) {
      state.success = null;
      state.isPartnerLoading = true;
      state.error = false;
      state.partner = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.success = null;
      state.isLoading = false;
      state.error = action.payload;
      state.data = null;
    },

    // GET USER
    getWorkoutsSuccess(state, action) {
      state.success = true;
      state.isLoading = false;
      state.error = false;
      state.data = action.payload;
    },

    getPartnerWorkoutSuccess(state, action) {
      state.success = true;
      state.isPartnerLoading = false;
      state.error = false;
      state.partner = action.payload;
    },

    setWorkout(state, action) {
      state.data = action.payload;
    },

    setPartnerWorkout(state, action) {
      state.partner = action.payload;
    },

    // RESET DATA
    resetData(state) {
      state.data = null;
      state.partner = null;
      state.isPartnerLoading = false;
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// Actions
export function getWorkout(id) {
  return async (dispatch, getState) => {
    const { isLoading, data } = getState().workout;
    const { collection } = getState().workouts;

    if (isLoading || data?.id === id) return;

    dispatch(actions.startLoading());
    try {
      const response = await axios.get(
        `/v1/data/workouts/${id}?collection=${collection}`
      );
      dispatch(actions.getWorkoutsSuccess(response.data));
      if (response.data.workout.partners.length > 0) {
        dispatch(getPartnerWorkout(id));
      }
    } catch (error) {
      dispatch(actions.hasError(error));
    }
  };
}

export function getPartnerWorkout(id) {
  return async (dispatch, getState) => {
    const { isPartnerLoading, partner, data } = getState().workout;
    const { collection } = getState().workouts;

    if (isPartnerLoading || partner) return;

    dispatch(actions.startPartnerLoading());
    try {
      const response = await axios.post(
        `/v1/data/workouts/get-partner-workout`,
        {
          name: data.name,
          collection,
          workout: {
            partners: data.workout.partners,
            start_timestamp: data.workout.start_timestamp,
            timestamp: data.workout.timestamp,
          },
        }
      );
      dispatch(actions.getPartnerWorkoutSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(actions.hasError(error));
    }
  };
}
