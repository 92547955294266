import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices

import userReducer from './slices/user';
import workoutsReducer from './slices/workouts';
import workoutReducer from './slices/workout';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  workouts: workoutsReducer,
  workout: workoutReducer,
});

export { rootPersistConfig, rootReducer };
