import { memo, useEffect, useState } from 'react';
import {
  SciChartSurface,
  NumericAxis,
  FastLineRenderableSeries,
  XyDataSeries,
  MouseWheelZoomModifier,
  RolloverModifier,
  LegendModifier,
  RubberBandXyZoomModifier,
  DateTimeNumericAxis,
  EllipsePointMarker,
  NumberRange,
  generateGuid,
} from 'scichart';
import { styled, useTheme } from '@mui/material/styles';
import { Button, ButtonGroup, Box } from '@mui/material';
import { bgBlur } from 'src/theme/css';

// ----------------------------------------------------------------------
SciChartSurface.configure({
  dataUrl: `https://cdn.jsdelivr.net/npm/scichart@3.4.623/_wasm/scichart2d.data`,
  wasmUrl: `https://cdn.jsdelivr.net/npm/scichart@3.4.623/_wasm/scichart2d.wasm`,
});

const ChartContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px', // Set the height to reduce the size of the graph
  '& .scichart-tooltip': {
    ...bgBlur({
      color: theme.palette.background.default,
    }),
    color: theme.palette.text.primary,
    boxShadow: theme.customShadows.dropdown,
    borderRadius: theme.shape.borderRadius * 1.25,
  },
  '& .scichart-legend': {
    display: 'flex',
    alignItems: 'center',
    '& .scichart-legend-item': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 8,
      '& .scichart-legend-marker': {
        width: 12,
        height: 12,
        borderRadius: 6,
        marginRight: 8,
      },
      '& .scichart-legend-text': {
        lineHeight: '18px',
        textTransform: 'capitalize',
      },
    },
  },
}));

const Chart = ({ options, series }) => {
  const [sciChartSurface, setSciChartSurface] = useState(null);
  const theme = useTheme();
  const [id] = useState(`sci-chart-${generateGuid()}`);

  useEffect(() => {
    const initSciChart = async () => {
      SciChartSurface.UseCommunityLicense();
      const { wasmContext, sciChartSurface } = await SciChartSurface.create(id);
      setSciChartSurface(sciChartSurface);

      sciChartSurface.xAxes.add(new DateTimeNumericAxis(wasmContext));
      sciChartSurface.yAxes.add(new NumericAxis(wasmContext));

      const colors = ['blue', 'red', 'green', 'purple', 'orange'];
      let colorIndex = 0;

      series.forEach((s, index) => {
        const dataSeries = new XyDataSeries(wasmContext, {
          dataSeriesName: s.name, // Set the series name here
        });

        // Append only non-zero values to the data series
        s.data.forEach((point) => {
          if (point.y !== null) {
            dataSeries.append(point.x, point.y);
          }
        });

        const pointMarker = new EllipsePointMarker(wasmContext, {
          width: 1,
          height: 1,
          strokeThickness: 1,
          fill: colors[colorIndex % colors.length],
          stroke: colors[colorIndex % colors.length],
        });

        const lineSeries = new FastLineRenderableSeries(wasmContext, {
          dataSeries,
          stroke: colors[colorIndex % colors.length],
          pointMarker, // Add point marker to the series
        });
        colorIndex += 1;
        sciChartSurface.renderableSeries.add(lineSeries);
      });

      const mouseWheelZoomModifier = new MouseWheelZoomModifier();
      const zoomRectangle = new RubberBandXyZoomModifier();
      sciChartSurface.chartModifiers.add(zoomRectangle, mouseWheelZoomModifier);

      const rolloverModifier = new RolloverModifier({
        rolloverLineStroke: '#ffffff',
        tooltipColor: '#fff',
        tooltipContainerBackground: '#000',
        showTooltip: true,
        showRollover: true,
        showAxisLabels: true,
        useInterpolation: true,
        formatTooltip: (dataSeriesName, xValue, yValue) => {
          return `${dataSeriesName}: (${new Date(
            xValue
          ).toLocaleDateString()}, ${yValue.toFixed(2)})`;
        },
      });
      sciChartSurface.chartModifiers.add(rolloverModifier);

      const legendModifier = new LegendModifier({
        placement: 'Right',
        orientation: 'Vertical',
        showCheckboxes: true,
        showLegend: true,
        itemStyle: {
          color: theme.palette.text.primary,
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius * 1.25,
        },
        getLegendItemText: (dataSeries) => dataSeries.dataSeriesName, // Set legend item text to series name
      });
      sciChartSurface.chartModifiers.add(legendModifier);

      sciChartSurface.applyTheme();
    };

    initSciChart();

    return () => {
      if (sciChartSurface) {
        sciChartSurface.delete();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series, theme]);

  const handleZoomIn = () => {
    if (sciChartSurface) {
      sciChartSurface.xAxes.asArray().forEach((axis) => {
        const visibleRange = axis.visibleRange;
        const center = (visibleRange.min + visibleRange.max) / 2;
        const range = (visibleRange.max - visibleRange.min) * 0.9;
        axis.visibleRange = new NumberRange(
          center - range / 2,
          center + range / 2
        );
      });
      sciChartSurface.yAxes.asArray().forEach((axis) => {
        const visibleRange = axis.visibleRange;
        const center = (visibleRange.min + visibleRange.max) / 2;
        const range = (visibleRange.max - visibleRange.min) * 0.9;
        axis.visibleRange = new NumberRange(
          center - range / 2,
          center + range / 2
        );
      });
    }
  };

  const handleZoomOut = () => {
    if (sciChartSurface) {
      sciChartSurface.xAxes.asArray().forEach((axis) => {
        const visibleRange = axis.visibleRange;
        const center = (visibleRange.min + visibleRange.max) / 2;
        const range = (visibleRange.max - visibleRange.min) * 1.1;
        axis.visibleRange = new NumberRange(
          center - range / 2,
          center + range / 2
        );
      });
      sciChartSurface.yAxes.asArray().forEach((axis) => {
        const visibleRange = axis.visibleRange;
        const center = (visibleRange.min + visibleRange.max) / 2;
        const range = (visibleRange.max - visibleRange.min) * 1.1;
        axis.visibleRange = new NumberRange(
          center - range / 2,
          center + range / 2
        );
      });
    }
  };

  const handleZoomExtents = () => {
    if (sciChartSurface) {
      sciChartSurface.zoomExtents();
    }
  };

  return (
    <>
      <ChartContainer id={id} />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <ButtonGroup
          variant='contained'
          color='primary'
          aria-label='zoom button group'
        >
          <Button onClick={handleZoomIn}>Zoom In</Button>
          <Button onClick={handleZoomOut}>Zoom Out</Button>
          <Button onClick={handleZoomExtents}>Reset</Button>
        </ButtonGroup>
      </Box>
    </>
  );
};

export default memo(Chart);
